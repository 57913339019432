const QrisStore = {
  state: {
    enableNextStep: false,
    businessData: {},
    personalData: {},
    bankAccountData: {},
  },
  mutations: {
    setEnableNextStep(state, data) {
      state.enableNextStep = data;
    },
    setBusinessData(state, data) {
      state.businessData = data;
    },
    setPersonalData(state, data) {
      state.personalData = data;
    },
    setBankAccountData(state, data) {
      state.bankAccountData = data;
    },
  },
  getters: {
    isEnableNextStep: (state) => {
      return state.enableNextStep;
    },
    getBusinessData: (state) => {
      return state.businessData;
    },
    getPersonalData: (state) => {
      return state.personalData;
    },
    getBankAccountData: (state) => {
      return state.bankAccountData;
    },
  },
};

export default QrisStore;
