const messages = {
  en: {
    message: {
      dashboard: 'Dashboard',
      transaction: 'Transaction',
      product: 'Product',
      inventory: 'Inventory',
      report: 'Report',
      promotion: 'Promotion',
      operational: 'Operational',
      staff: 'Staff',
      supplier: 'Supplier',
      customer: 'Customer',
      settings: 'Settings',
      tableManagement: 'Table Management',
      goToPOS: 'Go To POS',
      goToBackoffice: 'Go To Backoffice',
      profile: 'Profile',
      helpCenter: 'Help Center',
      closeCashier: 'Close Cashier',
      openCashier: 'Open Cashier'
    }
  },
  id: {
    message: {
      dashboard: 'Dashboard',
      transaction: 'Transaksi',
      product: 'Produk',
      inventory: 'Inventori',
      report: 'Laporan',
      promotion: 'Promosi',
      operational: 'Operasional',
      staff: 'Staff',
      supplier: 'Pembelian',
      customer: 'Pelanggan',
      settings: 'Pengaturan',
      tableManagement: 'Manajemen Meja',
      goToPOS: 'Masuk POS',
      goToBackoffice: 'Masuk Backoffice',
      profile: 'Profil',
      helpCenter: 'Pusat Bantuan',
      closeCashier: 'Tutup Kasir',
      openCashier: 'Buka Kasir',
    }
  }
}
export default messages
