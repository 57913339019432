const tableManagementStore = {
  state: {
    tables: [],
    areas: [],
  },
  mutations: {
    setTables(state, tables) {
      state.tables = tables;
    },
    setAreas(state, data) {
      state.areas = data;
    },
  },
  getters: {
    getAreas: (state) => {
      return state.areas;
    },
  },
  actions: {
    async fetchTables({ commit }) {
      try {
        const response = await this.Axios.get(
          `${process.env.VUE_APP_API_URL}table-management/get-tables`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );
        commit('setTables', response.data);
      } catch (error) {
        console.error(error);
      }
    },
  },
};

export default tableManagementStore;
