import axios from 'axios';

const POSStore = {
  state: {
    isCashierOpen: false,
    isSplitBill: false,
    splitType: '',
    totalSplitBill: 0,
    customers: [],
    categories: [],
    orderTypes: [],
    products: [],
    cart: [],
    cartDeleted: [],
    paymentMethods: [],
    subtotal: 0,
    total: 0,
    remainingPayment: 0,
    isSplitPayment: false,
    discount: undefined,
    discountValue: 0,
    orderTypeValue: 0,
    selectedCustomer: '',
    selectedOrderType: '',
    orderReff: '',
    detailHold: {},
    detailTransaksi: {},
    struk: {},
    strukPesanan: {},
    taxSetting: {},
    taxRate: 0,
    taxPercent: 0,
    taxerId: 0,
    biayaLayanan: 0,
    servicePercent: 0,
    saleId: 0,
    detailId: 0,
    isRemoveHold: false
  },
  mutations: {
    set_split_bill(state, data) {
      state.isSplitBill = data.isSplitBill;
      state.totalSplitBill = data.total;
      state.splitType = data.type;
      state.biayaLayanan = data.serviceRate;
      state.taxRate = data.taxRate;
      state.discountValue = data.discountRate;
    },
    unset_split_bill(state) {
      state.isSplitBill = false;
      state.totalSplitBill = 0;
    },
    set_order_reff(state, data) {
      state.orderReff = data;
    },
    set_products(state, data) {
      state.products = data;
    },
    set_detail_transaksi(state, data) {
      state.detailTransaksi = data;
    },
    set_detail_hold(state, data) {
      state.detailHold = data;
    },
    set_order_type(state, data) {
      state.selectedOrderType = data;

      // ** Set order type value
      if (data !== '') {
        const found = state.orderTypes.find((v) => v.id === data);
        state.orderTypeValue = found?.nilai;
      }
      else {
        state.orderTypeValue = 0;
      }
    },
    set_customer(state, data) {
      state.selectedCustomer = data;
    },
    set_saleId(state, data) {
      state.saleId = data;
    },
    set_detailId(state, data) {
      state.detailId = data;
    },
    set_is_remove_hold(state, data) {
      state.isRemoveHold = data;
    },
    add_to_cart_deleted(state, data) {
      state.cartDeleted.push(data);
    },
    add_to_cart(state, data) {
      // ** Check whether item is already exist
      const { product_id, variant_id } = data;
      const foundItem = state.cart.find((v) => {
        return v.product_id === product_id && v.variant_id === variant_id;
      });
      const foundIndex = state.cart.findIndex((v) => {
        return v.product_id === product_id && v.variant_id === variant_id;
      });
      if (foundItem !== undefined) {
        // ** Increment QTY
        state.cart[foundIndex].qty += data.qty;
        state.cart[foundIndex].subtotal =
          parseFloat(state.cart[foundIndex].qty) *
          parseInt(state.cart[foundIndex].price);
      } else {
        state.cart.push(data);
      }
    },
    update_cart(state, data) {
      const { product_id, variant_id, is_variant } = data;
      let index;
      if (is_variant) {
        index = state.cart.findIndex(
          (v) => v.product_id === product_id && v.variant_id === variant_id
        );
      } else {
        index = state.cart.findIndex(
          (v) => v.product_id === product_id && !v.is_variant
        );
      }
      if (index !== -1) {
        // ** Jika ada diskon
        if (data.diskon !== '') {
          Object.assign(state.cart[index], {
            normal_price: data.normal_price,
          });
        } else {
          delete state.cart[index].normal_price;
        }

        state.cart[index].qty = data.qty;
        state.cart[index].notes = data.notes;
        state.cart[index].diskon = data.diskon;
        state.cart[index].price = data.price;
        state.cart[index].subtotal = data.qty * data.price;
      }
    },
    add_discount(state, data) {
      state.discount = data;
    },
    remove_discount(state) {
      state.discount = undefined;
      state.discountValue = 0;
    },
    remove_from_cart(state, index) {
      state.cart.splice(index, 1);
    },
    remove_delete_cart(state) {
      state.cartDeleted = [];
    },
    remove_all_cart(state) {
      state.cart = [];
      state.total = 0;
      state.discount = undefined;
      state.discountValue = 0;
      state.subtotal = 0;
    },
    remove_detail_hold(state) {
      state.detailHold = {};
    },
    count_total(state) {
      let total = 0;
      state.subtotal = 0;
      state.cart.forEach((v) => {
        total += v.subtotal;
        state.subtotal += v.subtotal;
      });

      state.subtotal = Math.ceil(state.subtotal);

      // ** Count biaya layanan
      if (
        state.taxSetting?.service_status &&
        state.taxSetting?.service_status === 'Aktif' &&
        state.taxSetting?.service_status !== undefined
      ) {
        const layananPrice =
          (state.subtotal * state.taxSetting.service_nominal) / 100;
        state.biayaLayanan = Math.ceil(layananPrice);
        total += layananPrice;
        state.servicePercent = state.taxSetting.service_nominal;
      }

      // ** Count discount
      if (state.discount !== undefined) {
        if (state.discount.tipe_nilai == 'nominal') {
          // ** Nominal
          total -= state.discount.nilai;
          state.discountValue = Math.ceil(state.discount.nilai);
        } else {
          // ** Percent
          let discountPrice = Math.ceil((state.discount.nilai * total) / 100);
          state.discountValue = discountPrice;
          total -= discountPrice;
        }
      } else {
        total -= state.discountValue;
      }

      // ** Count pajak
      if (
        state.taxSetting.tax_status == 'Aktif' &&
        state.taxSetting.tax_status !== undefined &&
        state.taxSetting.pajak_exclude == 'Aktif'
      ) {
        const taxRate = Math.ceil(
          (total * state.taxSetting.tax_nominal) / 100
        );
        state.taxRate = taxRate;
        total += taxRate;
        state.taxPercent = state.taxSetting.tax_nominal;
      }

      state.total = Math.ceil(total);
    },
    set_total(state, data) {
      state.total = data;
    },
    set_remaining_payment(state, data) {
      state.remainingPayment = data;
    },
    set_is_split_payment(state, data) {
      state.isSplitPayment = data;
    },
    increment(state, index) {
      state.cart[index].qty++;
      state.cart[index].qty_sold++;
      state.cart[index].subtotal =
        parseFloat(state.cart[index].qty) * parseInt(state.cart[index].price);
    },
    decrement(state, index) {
      state.cart[index].qty--;
      state.cart[index].qty_sold--;
      state.cart[index].subtotal =
        parseFloat(state.cart[index].qty) * parseInt(state.cart[index].price);
    },
    reset_qty_item(state, index) {
      state.cart[index].qty = 1;
      state.cart[index].subtotal =
        parseFloat(state.cart[index].qty) * parseInt(state.cart[index].price);
    },
    set_qty_item(state, data) {
      state.cart[data.index].qty = data.qty;
      state.cart[data.index].subtotal =
        parseFloat(state.cart[data.index].qty) *
        parseInt(state.cart[data.index].price);
    },
    change_notes(state, data) {
      const { index, notes } = data;
      state.cart[index].notes = notes;
    },
    set_is_cashier_open(state, data) {
      state.isCashierOpen = data;
    },
    fetch_customers(state, search = '') {
      axios
        .get(`${process.env.VUE_APP_API_URL}pelanggan/home?search=${search}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
        .then((res) => {
          const response = res.data;
          state.customers = response.data;
        });
    },
    fetch_elements(state) {
      axios
        .get(`${process.env.VUE_APP_API_URL}pos/support-data`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
        .then((res) => {
          const data = res.data;
          state.categories = data.category;
          state.orderTypes = data.tipe_pesanan;
          state.paymentMethods = data.metode_pembayaran;
          state.taxSetting = data.pajak_master[0];
          state.taxerId = data.pajak_master[0]?.id;
        });
    },
    fetch_payment_receipts(state) {
      axios
        .get(`${process.env.VUE_APP_API_URL}struk/preview?type=Pembayaran`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
        .then((res) => {
          const response = res.data;
          state.struk = response.data;
        });
    },
    fetch_order_receipts(state) {
      axios
        .get(`${process.env.VUE_APP_API_URL}struk/preview?type=Pesanan`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
        .then((res) => {
          const response = res.data;
          state.strukPesanan = response.data;
        });
    },
  },
  getters: {
    orderReff: (state) => {
      return state.orderReff;
    },
    isSplitBill: (state) => {
      return state.isSplitBill;
    },
    splitType: (state) => {
      return state.splitType;
    },
    totalSplitBill: (state) => {
      return state.totalSplitBill;
    },
    products: (state) => {
      return state.products;
    },
    paymentMethods: (state) => {
      return state.paymentMethods;
    },
    struk: (state) => {
      return state.struk;
    },
    strukPesanan: (state) => {
      return state.strukPesanan;
    },
    detailTransaksi: (state) => {
      return state.detailTransaksi;
    },
    detailHold: (state) => {
      return state.detailHold;
    },
    orderType: (state) => {
      return state.selectedOrderType;
    },
    orderTypeValue: (state) => {
      return state.orderTypeValue;
    },
    selectedCustomer: (state) => {
      return state.selectedCustomer;
    },
    cart: (state) => {
      return state.cart;
    },
    cartDeleted: (state) => {
      return state.cartDeleted;
    },
    total: (state) => {
      return state.total;
    },
    remainingPayment: (state) => {
      return state.remainingPayment;
    },
    isSplitPayment: (state) => {
      return state.isSplitPayment;
    },
    subtotal: (state) => {
      return state.subtotal;
    },
    discount: (state) => {
      return state.discount;
    },
    discountValue: (state) => {
      return state.discountValue;
    },
    is_cashier_open: (state) => {
      return state.isCashierOpen;
    },
    customers: (state) => {
      return state.customers;
    },
    categories: (state) => {
      return state.categories;
    },
    orderTypes: (state) => {
      return state.orderTypes;
    },
    taxSetting: (state) => {
      return state.taxSetting;
    },
    biayaLayanan: (state) => {
      return state.biayaLayanan;
    },
    servicePercent: (state) => {
      return state.servicePercent;
    },
    taxRate: (state) => {
      return state.taxRate;
    },
    taxPercent: (state) => {
      return state.taxPercent;
    },
    taxerId: (state) => {
      return state.taxerId;
    },
    saleId: (state) => {
      return state.saleId;
    },
    detailId: (state) => {
      return state.detailId;
    },
    isRemoveHold: (state) => {
      return state.isRemoveHold;
    }
  },
};
export default POSStore;
