const strukPembayaran = {
    state: {
        elementStruk: {},
        warehouseID: '',
        id: '',        
        isLogoShow: false,
        isBusinessShow: false,
        businessName: '',
        warehouseName: '',
        phone: '',
        address: '',
        isWarehouseShow: false,
        isPhoneShow: false,
        isAddressShow: false,
        isHeaderShow: false,
        isFooterShow: false,
        isQollega: false,
        footer: '',
        logo: null,
        logoPreview: '',
        isNoAntrian: false
    },
    mutations: {
        set_id_pembayaran(state, data) {
            state.id = data
        },
        set_warehouse_id_pembayaran(state, data) {
            state.warehouseID = data
        },
        set_logo_pembayaran(state, data) {
            state.isLogoShow = data
        },
        set_logo_image_pembayaran(state, data) {
            state.logo = data
        },
        set_logo_preview_pembayaran(state, data) {
            state.logoPreview = data
        },
        set_business_pembayaran(state, data) {
            state.isBusinessShow = data
        },
        set_business_name_pembayaran(state, data) {
            state.businessName = data
        },
        set_warehouse_pembayaran(state, data) {
            state.isWarehouseShow = data
        },
        set_warehouse_name_pembayaran(state, data) {
            state.warehouseName = data
        },
        set_address_pembayaran(state, data) {
            state.isAddressShow = data
        },
        set_address_name_pembayaran(state, data) {
            state.address = data
        },
        set_phone_pembayaran(state, data) {
            state.isPhoneShow = data
        },
        set_phone_number_pembayaran(state, data) {
            state.phone = data
        },
        set_header_pembayaran(state, data) {
            state.isHeaderShow = data
        },
        set_footer_pembayaran(state, data) {
            state.isFooterShow = data
        },
        set_footer_text_pembayaran(state, data) {
            state.footer = data
        },
        set_is_qollega_pembayaran(state, data) {
            state.isQollega = data
        },
        set_is_no_antrian_pembayaran(state, data) {
            state.isNoAntrian = data
        }
    },
    getters: {
        get_id_pembayaran: state => {
            return state.id
        },
        get_element_struk: state => {
            return state.elementStruk
        },
        get_warehouse_id_pembayaran: state => {
            return state.warehouseID
        },
        get_logo_image_pembayaran: state => {
            return state.logo
        },
        get_logo_pembayaran: state => {
            return state.isLogoShow
        },
        get_logo_preview_pembayaran: state => {
            return state.logoPreview
        },
        get_business_pembayaran: state => {
            return state.isBusinessShow
        },
        get_business_name_pembayaran: state => {
            return state.businessName
        },
        get_warehouse_pembayaran: state => {
            return state.isWarehouseShow
        },
        get_warehouse_name_pembayaran: state => {
            return state.warehouseName
        },
        get_address_pembayaran: state => {
            return state.isAddressShow
        },
        get_address_name_pembayaran: state => {
            return state.address
        },
        get_phone_pembayaran: state => {
            return state.isPhoneShow
        },
        get_phone_number_pembayaran: state => {
            return state.phone
        },
        get_header_pembayaran: state => {
            return state.isHeaderShow
        },
        get_footer_pembayaran: state => {
            return state.isFooterShow
        },
        get_footer_text_pembayaran: state => {
            return state.footer
        },
        get_is_qollega_pembayaran: state => {
            return state.isQollega
        },
        get_is_no_antrian_pembayaran: state => {
            return state.isNoAntrian
        }
    }
}

export default strukPembayaran
